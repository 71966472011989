import { Script } from 'gatsby';

const ThirdPartyResources = () => {
    const adobeUrl = process.env.GATSBY_LAUNCH_SCRIPT_URL;

    return (
        <>
            <Script id="adobeLaunchScript" src={adobeUrl} async />
            <Script
                id="marketoScript"
                src="https://app-ab41.marketo.com/js/forms2/js/forms2.min.js"
                async
            />
            <Script id="munchkinScript" src="https://munchkin.marketo.net/munchkin.js" async />
            <Script
                id="sentryScript"
                src="https://js.sentry-cdn.com/2da199f1a8644a77a9973547ff9d44f7.min.js"
                crossOrigin="anonymous"
                async
            />
            <Script id="digicertSealScript" async>
                {`
                var __dcid = __dcid || [];

                __dcid.push({ cid: 'DigiCertClickID_onwANETs', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_1', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_2', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_3', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_4', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_5', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_6', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_7', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_8', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_9', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_10', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_11', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_12', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_13', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_14', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_15', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_16', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_17', tag: 'onwANETs' });
                __dcid.push({ cid: 'DigiCertClickID_onwANETs_18', tag: 'onwANETs' });

                window.__dcid = __dcid;

                (function(){
                    var cid=document.createElement("script");
                    cid.async=true;
                    cid.src="//seal.digicert.com/seals/cascade/seal.min.js";
                    var s = document.getElementsByTagName("script");
                    var ls = s[(s.length - 1)];
                    ls.parentNode.insertBefore(cid, ls.nextSibling);
                }())
                `}
            </Script>
            <Script id="hotjarScript" async>
                {`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:${process.env.GATSBY_HOT_JAR_ID},hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
            </Script>
        </>
    );
};

export default ThirdPartyResources;
