const constants = Object.freeze({
    DESKTOP_BREAKPOINT: 992,
    TABLET_BREAKPOINT: 768,
    DEFAULT_ALLIANCE_ID: 'Med34728',
    DEFAULT_SEO_ALLIANCE_ID: 'Med37949',
    DEFAULT_PHONE: '877-543-6619',
    DEFAULT_MS_SERVICE_HOURS: 'Mon - Fri: 9:45 AM - 7 PM ET',
    AEP_SERVICE_HOURS: 'Mon - Fri: 9:45am - 7pm; Sat & Sun: 10am - 7pm ET',
    EHM_TRACKING_PARAMETERS: [
        'showPdpOnly',
        'hideContact',
        'leadFormEnabled',
        'hideEnrollPage',
        'defaultThrottle',
        'aep2020',
        'planYear',
        'isShowPlanYearElement',
        'previewPeriod',
        'leadFormEmbed',
        'leadForm',
        'defaultOpen',
        'detailCompareRedesign',
        'planUnitRedesign',
        'medicarecom',
        'mobileRedesign',
        'msType',
        'personalCode',
        'n2m',
        '_ga',
        'adobe_mc',
        '_gac',
    ] as EhmTrackingParameters[],
    OTHER_TRACKING_PARAMETERS: [
        'lastV1Action',
        'type',
        'sid',
        'pid',
        'leadId',
        'campaign',
    ] as OtherTrackingParameters[],
    CARRIER_ID: {
        aetna: '6:3',
        anthem: '6:113',
        cigna: '6:126',
        humana: '6:66',
        kaiser: '6:70',
        uhc: '6:108',
        wellcare: '6:124',
    },
    // to be updated annually before AEP:
    EXCLUDED_COUNTIES: [
        'SABINE, LA',
        'BOX BUTTE, NE',
        'WHEELER, TX',
        'BLAINE, NE',
        'BRULE, SD',
        'DUNN, WI',
        'ANCHORAGE, AK',
        'NORTHWEST ARCTIC, AK',
        'EAST FELICIANA, LA',
        'BUFFALO, NE',
        'FAIRBANKS NORTH STAR, AK',
        'GOODHUE, MN',
        'SANTA CLARA, CA',
        'MAYAGUEZ, PR',
        'MOROVIS, PR',
        'MUSKEGON, MI',
        'TRINITY, CA',
        'EL DORADO, CA',
        'FINNEY, KS',
        'WEST CARROLL, LA',
        'AMADOR, CA',
        'STEARNS, MN',
        'CUSTER, ID',
        'CEDAR, NE',
        'MONROE, FL',
        'SWEETWATER, WY',
        'TUOLUMNE, CA',
        'MARICAO, PR',
        'GUAYANILLA, PR',
        'MAUNABO, PR',
        'BARNSTABLE, MA',
        'TENSAS, LA',
        'ORLEANS, VT',
        'TERREBONNE, LA',
        'CLAIBORNE, LA',
        'WASHBURN, WI',
        'GLADWIN, MI',
        'IMPERIAL, CA',
        'BLAINE, MT',
        'OLMSTED, MN',
        'IDAHO, ID',
        'SKAMANIA, WA',
        'NEW LONDON, CT',
        'HAND, SD',
        'UMATILLA, OR',
        'SCOTTS BLUFF, NE',
        'SAINT CHARLES, LA',
        'GUANICA, PR',
        'WILBARGER, TX',
        'ONEIDA, ID',
        'SHERIDAN, KS',
        'YOLO, CA',
        'RENVILLE, ND',
        'ROUTT, CO',
        'CUSTER, MT',
        'ESMERALDA, NV',
        'KANDIYOHI, MN',
        'MENOMINEE, MI',
        'RICE, MN',
        'MEADE, KS',
        'MERRICK, NE',
        'CAVALIER, ND',
        'HUMACAO, PR',
        'CAYEY, PR',
        'QUEBRADILLAS, PR',
        'RIO BLANCO, CO',
        'DAGGETT, UT',
        'CHEYENNE, KS',
        'BOWMAN, ND',
        'CIMARRON, OK',
        'MATANUSKA SUSITNA, AK',
        'MODOC, CA',
        'WAHKIAKUM, WA',
        'GARFIELD, MT',
        'TREASURE, MT',
        'JACKSON, OR',
        'CHASE, NE',
        'RIO GRANDE, CO',
        'SITKA, AK',
        'FAIRFIELD, CT',
        'BENEWAH, ID',
        'FRANKLIN, LA',
        'CALCASIEU, LA',
        'VILLALBA, PR',
        'MCKINLEY, NM',
        'DOUGLAS, MN',
        'PHELPS, NE',
        'REPUBLIC, KS',
        'KODIAK ISLAND, AK',
        'WILLIAMSON, IL',
        'ARCHER, TX',
        'DORADO, PR',
        'LUQUILLO, PR',
        'COMERIO, PR',
        'NANTUCKET, MA',
        'PERKINS, NE',
        'VEGA BAJA, PR',
        'SANTA ISABEL, PR',
        'ROOKS, KS',
        'HILL, MT',
        'WINDHAM, VT',
        'PROVIDENCE, RI',
        'FREMONT, WY',
        'GREGORY, SD',
        'CARBON, UT',
        'BOUNDARY, ID',
        'KLICKITAT, WA',
        'STOREY, NV',
        'CULEBRA, PR',
        'CLINTON, NY',
        'CASCADE, MT',
        'LEWIS, ID',
        'WINONA, MN',
        'TOOLE, MT',
        'PIUTE, UT',
        'CATAHOULA, LA',
        'ARTHUR, NE',
        'CARTERET, NC',
        'FREDERICK, MD',
        'EATON, MI',
        'AGUAS BUENAS, PR',
        'LAJAS, PR',
        'TETON, ID',
        'VERMILION, LA',
        'BROWN, TX',
        'BRISTOL, RI',
        'FOSTER, ND',
        'WHITE PINE, NV',
        'ALAMOSA, CO',
        'WASHINGTON, LA',
        'GOSPER, NE',
        'LARES, PR',
        'COROZAL, PR',
        'SALINAS, PR',
        'PIERCE, NE',
        'FRANKLIN, VT',
        'KLAMATH, OR',
        'SAWYER, WI',
        'ACADIA, LA',
        'BENTON, OR',
        'SHERIDAN, ND',
        'HAWAII, HI',
        'MARTIN, MN',
        'BOTTINEAU, ND',
        'BALTIMORE CITY, MD',
        'WASHINGTON, MD',
        'LANE, OR',
        'GRANT, SD',
        'ASCENSION, LA',
        'ZIEBACH, SD',
        'BEAR LAKE, ID',
        'COFFEY, KS',
        'LINCOLN, WI',
        'MINERAL, CO',
        'CAMUY, PR',
        'SHIAWASSEE, MI',
        'LINCOLN, NV',
        'GRAY, KS',
        'REDWOOD, MN',
        'LIVINGSTON, LA',
        'SISKIYOU, CA',
        'BACA, CO',
        'POWER, ID',
        'COTTLE, TX',
        'SOLANO, CA',
        'STEVENS, WA',
        'COOS, NH',
        'RICHLAND, LA',
        'WELLS, ND',
        'RUSK, WI',
        'PRICE, WI',
        'PLYMOUTH, MA',
        'SAN SEBASTIAN, PR',
        'BENNETT, SD',
        'EDMUNDS, SD',
        'COLLINGSWORTH, TX',
        'DOLORES, CO',
        'VALLEY, ID',
        'OUACHITA, LA',
        'BALTIMORE, MD',
        'HOWARD, NE',
        'SONOMA, CA',
        'RICHLAND, MT',
        'WEBSTER, NE',
        'CORSON, SD',
        'UNION, NM',
        'ARROYO, PR',
        'CALEDONIA, VT',
        'HITCHCOCK, NE',
        'SUTTER, CA',
        'JEFFERSON, OR',
        'LANDER, NV',
        'BUTTE, ID',
        'KOOTENAI, ID',
        'HAINES, AK',
        'UTUADO, PR',
        'HOWARD, MD',
        'CHUGACH, AK',
        'SOUTHEAST FAIRBANKS, AK',
        'BUFFALO, WI',
        'LAKE, OR',
        'LINCOLN, MN',
        'EUREKA, NV',
        'STEVENS, KS',
        'BARTON, KS',
        'GRANT, LA',
        'PENNINGTON, SD',
        'GRANT, ND',
        'GRANT, KS',
        'RICHARDSON, NE',
        'WASHITA, OK',
        'PONDERA, MT',
        'MEADE, SD',
        'NELSON, ND',
        'CHEYENNE, NE',
        'JACKSON, MN',
        'EAST CARROLL, LA',
        'GRANT, OR',
        'LYON, MN',
        'SUFFOLK, NY',
        'JUNCOS, PR',
        'TOA BAJA, PR',
        'CARSON CITY, NV',
        'LATAH, ID',
        'MONTMORENCY, MI',
        'LOIZA, PR',
        'BARBER, KS',
        'RAMSEY, ND',
        'ALPINE, CA',
        'SAN JUAN, WA',
        'WORCESTER, MD',
        'NEZ PERCE, ID',
        'BOYD, NE',
        'DE SOTO, LA',
        'EVANGELINE, LA',
        'PEND OREILLE, WA',
        'OSBORNE, KS',
        'JONES, SD',
        'WHEELER, OR',
        'CHIPPEWA, WI',
        'CHAUTAUQUA, KS',
        'PARK, MT',
        'SIOUX, NE',
        'SAINT MARTIN, LA',
        'ADJUNTAS, PR',
        'FLORIDA, PR',
        'CANOVANAS, PR',
        'WASHINGTON, VT',
        'TREGO, KS',
        'MARIPOSA, CA',
        'MADISON, MT',
        'SAINT LANDRY, LA',
        'OTOE, NE',
        'FAULK, SD',
        'MOFFAT, CO',
        'EMMONS, ND',
        'KNOX, NE',
        'COMANCHE, KS',
        'CIDRA, PR',
        'CHESHIRE, NH',
        'CARROLL, NH',
        'DUNDY, NE',
        'LOGAN, ND',
        'DODGE, MN',
        'RUTLAND, VT',
        'JACKSON, WI',
        'LAKE AND PENINSULA, AK',
        'FILLMORE, MN',
        'LINCOLN, OR',
        'DAWSON, NE',
        'GARDEN, NE',
        'BIENVILLE, LA',
        'HALL, NE',
        'VALLEY, MT',
        'GARRETT, MD',
        'CAMPBELL, WY',
        'CONVERSE, WY',
        'CALAVERAS, CA',
        'MENDOCINO, CA',
        'JACKSON, MI',
        'BRISTOL BAY, AK',
        'SHERBURNE, MN',
        'GRAYS HARBOR, WA',
        'DENALI, AK',
        'PRESQUE ISLE, MI',
        'VERNON, LA',
        'DOUGLAS, WA',
        'BARRANQUITAS, PR',
        'SARGENT, ND',
        'CONCORDIA, LA',
        'SCHOOLCRAFT, MI',
        'CADDO, LA',
        'ADAMS, MS',
        'RICHLAND, WI',
        'RUSH, KS',
        'MCINTOSH, ND',
        'WHITMAN, WA',
        'ARECIBO, PR',
        'YABUCOA, PR',
        'GUNNISON, CO',
        'STEELE, ND',
        'ASSUMPTION, LA',
        'CLATSOP, OR',
        'DILLINGHAM, AK',
        'MACKINAC, MI',
        'GREELEY, NE',
        'UNION, OR',
        'PAYNE, OK',
        'CHURCHILL, NV',
        'TRUJILLO ALTO, PR',
        'NAGUABO, PR',
        'HOOKER, NE',
        'PROWERS, CO',
        'BONNER, ID',
        'SHERIDAN, WY',
        'MILLARD, UT',
        'HOUSTON, MN',
        'KETCHIKAN GATEWAY, AK',
        'FALL RIVER, SD',
        'LAFAYETTE, LA',
        'STEVENS, MN',
        'DUNN, ND',
        'SAN GERMAN, PR',
        'GRAND ISLE, VT',
        'WASHAKIE, WY',
        'ADAMS, NE',
        'CUSTER, OK',
        'HAYES, NE',
        'KIT CARSON, CO',
        'EMERY, UT',
        'SEWARD, KS',
        'CLARE, MI',
        'MOREHOUSE, LA',
        'WEST FELICIANA, LA',
        'SEVIER, UT',
        'LAKE, CA',
        'MCKENZIE, ND',
        'DAWES, NE',
        'WINN, LA',
        'PLATTE, WY',
        'EAGLE, CO',
        'ORLEANS, LA',
        'CLOUD, KS',
        'PHILLIPS, MT',
        'JUAB, UT',
        'ONEIDA, WI',
        'VILAS, WI',
        'MADISON, LA',
        'JOHNSON, WY',
        'DEL NORTE, CA',
        'PERSHING, NV',
        'OKANOGAN, WA',
        'KALAWAO, HI',
        'THROCKMORTON, TX',
        'DICKEY, ND',
        'HORMIGUEROS, PR',
        'TRIPP, SD',
        'CARROLL, MD',
        'FALLON, MT',
        'SOMERSET, MD',
        'KITTITAS, WA',
        'NATCHITOCHES, LA',
        'TILLAMOOK, OR',
        'TANGIPAHOA, LA',
        'CUSTER, SD',
        'MCHENRY, ND',
        'MURRAY, MN',
        'RICHMOND, NY',
        'LAMOURE, ND',
        'DEUEL, NE',
        'HARNEY, OR',
        'NORTON, KS',
        'LEA, NM',
        'JACKSON, LA',
        'MONTEREY, CA',
        'YELLOW MEDICINE, MN',
        'PEPIN, WI',
        'LENAWEE, MI',
        'SIERRA, CA',
        'CAROLINA, PR',
        'HAMPSHIRE, MA',
        'CEIBA, PR',
        'STARK, ND',
        'SACRAMENTO, CA',
        'LAPEER, MI',
        'MOUNTRAIL, ND',
        'RILEY, KS',
        'WALLOWA, OR',
        'BAKER, OR',
        'GRANT, NE',
        'ALEUTIANS WEST, AK',
        'RENVILLE, MN',
        'LINCOLN, NE',
        'YAKUTAT, AK',
        'BILLINGS, ND',
        'ROOSEVELT, MT',
        'WOODSON, KS',
        'YUKON KOYUKUK, AK',
        'SAN LORENZO, PR',
        'GRANT, WA',
        'BUTTE, SD',
        'SAINT CROIX, WI',
        'CALDWELL, LA',
        'CLINTON, MI',
        'DOUGLAS, OR',
        'WICHITA, KS',
        'LASSEN, CA',
        'CHARLES, MD',
        'SHOSHONE, ID',
        'MORTON, KS',
        'RIO GRANDE, PR',
        'LAS PIEDRAS, PR',
        'KENT, MD',
        'LOWNDES, MS',
        'JEFFERSON, LA',
        'MASON, MI',
        'CLARK, KS',
        'BOONE, NE',
        'SANPETE, UT',
        'WASCO, OR',
        'JUNEAU, AK',
        'ALLEGANY, MD',
        'RAPIDES, LA',
        'ANTELOPE, NE',
        'HYDE, SD',
        'LAC QUI PARLE, MN',
        'WEBSTER, LA',
        'DAWSON, MT',
        'ELKO, NV',
        'MANATI, PR',
        'PRINCE GEORGES, MD',
        'ONTARIO, NY',
        'GLENN, CA',
        'NORFOLK, MA',
        'WICOMICO, MD',
        'FRONTIER, NE',
        'KEYA PAHA, NE',
        'CARIBOU, ID',
        'JOSEPHINE, OR',
        'WRANGELL, AK',
        'LA SALLE, LA',
        'BERRIEN, MI',
        'KUSILVAK, AK',
        'EAST BATON ROUGE, LA',
        'WILLIAMS, ND',
        'BURNETT, WI',
        'PERKINS, SD',
        'MONO, CA',
        'BELKNAP, NH',
        'MIDLAND, MI',
        'TALBOT, MD',
        'LOUP, NE',
        'TOMPKINS, NY',
        'FRANKLIN, NE',
        'LYMAN, SD',
        'GREELEY, KS',
        'THOMAS, NE',
        'SAINT BERNARD, LA',
        'PAWNEE, NE',
        'ISABELA, PR',
        'TOA ALTA, PR',
        'SANTA CRUZ, CA',
        'WASHINGTON, RI',
        'SAINT JAMES, LA',
        'LINCOLN, LA',
        'POLK, OR',
        'RED WILLOW, NE',
        'NEVADA, CA',
        'CAMERON, LA',
        'SHERMAN, OR',
        'KIMBALL, NE',
        'OROCOVIS, PR',
        'FAJARDO, PR',
        'FRANKLIN, MA',
        'SAN MATEO, CA',
        'EAU CLAIRE, WI',
        'AVOYELLES, LA',
        'NOBLES, MN',
        'BROWN, SD',
        'HARRISONBURG CITY, VA',
        'LEMHI, ID',
        'FURNAS, NE',
        'DORCHESTER, MD',
        'HOLT, NE',
        'MCPHERSON, NE',
        'COLUSA, CA',
        'JOHNSON, NE',
        'ASOTIN, WA',
        'GRANT, MN',
        'ROGER MILLS, OK',
        'CLALLAM, WA',
        'MADISON, ID',
        'CLARK, ID',
        'CLAY, NE',
        'PATILLAS, PR',
        'SAINT MARYS, MD',
        'CHITTENDEN, VT',
        'SAINT CLAIR, MI',
        'ELK, KS',
        'HIDALGO, NM',
        'CALLAHAN, TX',
        'SAN MIGUEL, CO',
        'HUGHES, SD',
        'JUNEAU, WI',
        'IRON, WI',
        'SULLIVAN, NH',
        'ELLIS, KS',
        'POTTAWATOMIE, KS',
        'MCPHERSON, SD',
        'MALHEUR, OR',
        'CHERRY, NE',
        'FREMONT, ID',
        'SAINT TAMMANY, LA',
        'COOS, OR',
        'WARD, TX',
        'JEFFERSON DAVIS, LA',
        'LEWIS AND CLARK, MT',
        'VAN BUREN, MI',
        'IBERIA, LA',
        'ASHLAND, WI',
        'DESCHUTES, OR',
        'LANE, KS',
        'WINDHAM, CT',
        'VALLEY, NE',
        'ORANGE, VT',
        'BUFFALO, SD',
        'COPPER RIVER, AK',
        'ALAMEDA, CA',
        'PACIFIC, WA',
        'POLK, WI',
        'SIOUX, ND',
        'PRAIRIE, MT',
        'HAMILTON, KS',
        'NORTH SLOPE, AK',
        'PARMER, TX',
        'ADAMS, WA',
        'CABO ROJO, PR',
        'VEGA ALTA, PR',
        'AIBONITO, PR',
        'COAMO, PR',
        'YUMA, CO',
        'EDDY, NM',
        'SWIFT, MN',
        'PIERCE, ND',
        'PETROLEUM, MT',
        'MORRILL, NE',
        'MARATHON, WI',
        'GRAHAM, KS',
        'WASHINGTON, OK',
        'LAFOURCHE, LA',
        'DUKES, MA',
        'DEWEY, SD',
        'LUCE, MI',
        'ALLEN, LA',
        'HUMBOLDT, CA',
        'NIOBRARA, WY',
        'GILLIAM, OR',
        'SHERIDAN, NE',
        'WINDSOR, VT',
        'QUEEN ANNES, MD',
        'SULLY, SD',
        'LOGAN, NE',
        'BETHEL, AK',
        'TERRELL, TX',
        'GOVE, KS',
        'SAINT MARY, LA',
        'TAYLOR, IA',
        'BARRY, MI',
        'CROCKETT, TX',
        'FARIBAULT, MN',
        'BEAVER, UT',
        'BEAUREGARD, LA',
        'STANTON, KS',
        'SAN BENITO, CA',
        'WIBAUX, MT',
        'MORROW, OR',
        'SHERMAN, NE',
        'TODD, SD',
        'BANNER, NE',
        'UNION, LA',
        'EDDY, ND',
        'BOSSIER, LA',
        'GUAYNABO, PR',
        'ESSEX, NY',
        'SCOTT, KS',
        'BERKSHIRE, MA',
        'POWDER RIVER, MT',
        'ROCK, MN',
        'HARMON, OK',
        'SAINT HELENA, LA',
        'HOUGHTON, MI',
        'ALGER, MI',
        'YAKIMA, WA',
        'COOK, MN',
        'CLAY, TX',
        'LIBERTY, MT',
        'DOUGLAS, WI',
        'HASKELL, KS',
        'SEDGWICK, CO',
        'PENUELAS, PR',
        'JAYUYA, PR',
        'MIDDLESEX, MA',
        'OTERO, NM',
        'ROSEBUD, MT',
        'BIG STONE, MN',
        'CRAWFORD, WI',
        'TEXAS, OK',
        'LINCOLN, KS',
        'CHOUTEAU, MT',
        'MERCED, CA',
        'YAUCO, PR',
        'GUAYAMA, PR',
        'LAMOILLE, VT',
        'HARDING, SD',
        'SILVER BOW, MT',
        'CHEYENNE, CO',
        'TEHAMA, CA',
        'PLACER, CA',
        'GALLATIN, MT',
        'INYO, CA',
        'CHIPPEWA, MI',
        'PLAQUEMINES, LA',
        'COLUMBIA, WA',
        'CARBON, WY',
        'LINCOLN, WA',
        'PLUMAS, CA',
        'AGUADA, PR',
        'CATANO, PR',
        'JUANA DIAZ, PR',
        'BARRON, WI',
        'KENT, RI',
        'NOME, AK',
        'CLARK, WI',
        'DOUGLAS, NV',
        'HAMILTON, NE',
        'MELLETTE, SD',
        'LOGAN, KS',
        'SAN JUAN, UT',
        'CHELAN, WA',
        'ST JOHN THE BAPTIST, LA',
        'NUCKOLLS, NE',
        'POPE, MN',
        'SUBLETTE, WY',
        'AGUADILLA, PR',
        'ANASCO, PR',
        'RINCON, PR',
        'MACOMB, MI',
        'MISSOULA, MT',
        'BEADLE, SD',
        'MCCONE, MT',
        'FERRY, WA',
        'BARAGA, MI',
        'MONTGOMERY, MD',
        'WAYNE, NY',
        'SHERIDAN, MT',
        'KEARNEY, NE',
        'TOWNER, ND',
        'SABANA GRANDE, PR',
        'BENNINGTON, VT',
        'LAWRENCE, SD',
        'CROOK, OR',
        'HODGEMAN, KS',
        'KEARNY, KS',
        'DELTA, MI',
        'WABASHA, MN',
        'RAWLINS, KS',
        'BAYFIELD, WI',
        'PHILLIPS, CO',
        'COTTONWOOD, MN',
        'HATILLO, PR',
        'THOMAS, KS',
        'CHAMPAIGN, IL',
        'HOONAH ANGOON, AK',
        'POINTE COUPEE, LA',
        'WAYNE, UT',
        'NATRONA, WY',
        'VIEQUES, PR',
        'GURABO, PR',
        'SLOPE, ND',
        'ESSEX, VT',
        'HARFORD, MD',
        'KIOWA, KS',
        'JACKSON, SD',
        'GRIGGS, ND',
        'NESS, KS',
        'SHASTA, CA',
        'POTTER, SD',
        'BEAVER, OK',
        'CHAVES, NM',
        'ANNE ARUNDEL, MD',
        'PRINCE OF WALES HYDER, AK',
        'ROCK, NE',
        'STANLEY, SD',
        'VAL VERDE, TX',
        'HINSDALE, CO',
        'DECATUR, KS',
        'CUSTER, NE',
        'CARTER, MT',
        'SUSSEX, NJ',
        'SKAGWAY, AK',
        'GAGE, NE',
        'INGHAM, MI',
        'STANTON, NE',
        'CECIL, MD',
        'CAGUAS, PR',
        'CHEBOYGAN, MI',
        'PETERSBURG, AK',
        'PIERCE, WI',
        'KEWEENAW, MI',
        'HARDING, NM',
        'ALEUTIANS EAST, AK',
        'YAMHILL, OR',
        'BAYAMON, PR',
        'NEWPORT, RI',
        'BENSON, ND',
        'CURRY, OR',
        'LIPSCOMB, TX',
        'FERGUS, MT',
        'MARQUETTE, MI',
        'FORD, KS',
        'FRANKLIN, NY',
        'MOWER, MN',
        'IONIA, MI',
        'WOOD, WI',
        'GRAFTON, NH',
        'MARION, OR',
        'DELAWARE, NY',
        'TAYLOR, WI',
        'BUTTE, CA',
        'HETTINGER, ND',
        'IBERVILLE, LA',
        'CHIPPEWA, MN',
        'GARFIELD, WA',
        'NARANJITO, PR',
        'HARLAN, NE',
        'MCCULLOCH, TX',
        'KEITH, NE',
        'KENAI PENINSULA, AK',
        'ADAMS, ND',
        'CHISAGO, MN',
        'ALPENA, MI',
        'HOOD RIVER, OR',
        'BROWN, NE',
        'DANIELS, MT',
        'HOT SPRINGS, WY',
        'OGLALA LAKOTA, SD',
        'PONCE, PR',
        'FREEBORN, MN',
        'CALVERT, MD',
        'UINTAH, UT',
        'WEST BATON ROUGE, LA',
        'STAFFORD, KS',
        'CAROLINE, MD',
        'YUBA, CA',
        'PITKIN, CO',
        'ISABELLA, MI',
        'RICH, UT',
        'SCURRY, TX',
        'WHEELER, NE',
        'WATONWAN, MN',
        'GOLDEN VALLEY, ND',
        'RED RIVER, LA',
        'CIALES, PR',
        'LAS MARIAS, PR',
        'GARFIELD, NE',
        'BIG HORN, WY',
        'EDWARDS, KS',
        'PIPESTONE, MN',
        'WARD, ND',
        'OURAY, CO',
        'GARFIELD, UT',
        'ADAMS, ID',
        'ELMORE, ID',
        'SAN JUAN, PR',
        'ADDISON, VT',
        'BARCELONETA, PR',
        'HEMPHILL, TX',
        'DUNKLIN, MO',
        'WOODWARD, OK',
        'OCHILTREE, TX',
        'KANE, UT',
        'DIVIDE, ND',
        'SHERMAN, KS',
        'BURKE, ND',
        'SAN JUAN, CO',
        'CLEARWATER, ID',
        'HAAKON, SD',
        'HUMBOLDT, NV',
        'KIOWA, CO',
        'ROLETTE, ND',
        'MOCA, PR',
        'JERAULD, SD',
        'HARPER, OK',
        'MINERAL, NV',
        'GARFIELD, CO',
        'WALLACE, KS',
        'GRANT, WI',
        'ELLIS, OK',
        'BECKHAM, OK',
    ],
    MS_STATES_WITH_DYNAMIC_HERO: [
        'AK',
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'MD',
        'ME',
        'MI',
        'MO',
        'MS',
        'NC',
        'NE',
        'NH',
        'NJ',
        'OH',
        'OH',
        'OK',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'VA',
        'VT',
        'WV',
        'WY',
    ],
    AEP_START_DATE: '10-15',
    AEP_END_DATE: '12-07',
});

export default constants;
